import React from 'react';
import * as S from './styles';
import eventos from './eventos.json';

function Events() {
  return (
    <S.Main>
      <S.Container>
        {eventos.map((evento, index) => (
          <S.EventCard key={index}>
            <a href={evento.link} target="_blank" rel="noreferrer">
              <h2>{evento.titulo}</h2>
            </a>
          </S.EventCard>
        ))}
      </S.Container>
    </S.Main>
  );
}

export default Events;
