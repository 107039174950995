import React from 'react';
import youtube from '../../images/icons/youtube.svg';

function Youtube() {
  return (
    <a
      href="https://www.youtube.com/channel/UCLn3J16_ujB95kuQnLVBX3g"
      target="_blank"
      className="ext"
      rel="noreferrer"
    >
      <img src={youtube} alt="youtube logo" />
    </a>
  );
}

export default Youtube;
