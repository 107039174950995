import React from 'react';
import * as S from './styles';

function About() {
  return (
    <S.Main>
      <S.Container>
        <S.ImgText>
          <S.Text>
            <h2>
              Coisa boa é quando a gente encontra amigos e amigas que têm uma
              proposta de trabalho afinada com a nossa!
            </h2>
            <div>
              <h2>Nossa missão</h2>
              <p>
                Somos um grupo de analistas em Belo Horizonte que tem por
                objetivo estabelecer uma rede de formação contínua para
                psicanalistas. Nossa proposta é oferecer palestras, seminários,
                minicursos, grupos de estudos e supervisões. Além disso, estamos
                construindo uma rede de clínicas sociais para que a população
                mais carente da nossa cidade tenha acesso a espaços de
                tratamento para seu sofrimento psíquico.
              </p>
            </div>
            <div>
              <h2>Nossa história</h2>
              <p>
                O <em>Travessias</em> nasceu de uma{' '}
                <strong>uma necessidade.</strong> Com trajetórias diversas,
                nossos caminhos se cruzaram na UFMG, onde nos graduamos em
                psicologia e nos pós-graduamos em psicanálise. Sabemos que a
                carreira clínica (sobretudo em consultório) é particularmente
                árdua no início e predominantemente solitária em todo o seu
                percurso, e vivenciamos também um contexto em que as formações
                em psicanálise, inclusive nas universidades, têm sido
                dogmaticamente reduzidas à leitura de autores específicos.
              </p>
              <p>
                Entre a solidão da clínica, as dificuldades de consolidar esse
                trabalho e formações cada vez mais unilaterais, nós nos
                organizamos enquanto grupo de modo a promover, nesta rede, tanto{' '}
                <strong>
                  o encontro entre psicanalistas e pessoas em busca de
                  tratamento
                </strong>{' '}
                quanto <strong>uma formação contínua.</strong>
              </p>
              <p>
                <em>Travessias – Percursos em Psicanálise</em> é um nome que
                conjuga melhor um dos nossos valores principais:{' '}
                <strong>a formação plural.</strong> Tornar-se analista é uma
                travessia: cada um a desenrola à sua maneira e a faz a partir
                dos diferentes encontros e desencontros teóricos, técnicos e
                afetivos que fazem nossos caminhos.
              </p>
            </div>
          </S.Text>
        </S.ImgText>
      </S.Container>
    </S.Main>
  );
}

export default About;
