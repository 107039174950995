import React from 'react';
import Instagram from '../Links/Instagram';
import GoogleDrive from '../Links/GoogleDrive';
import Youtube from '../Links/Youtube';
import Whatsapp from '../Links/Whatsapp';

import * as S from './styles';

function Footer() {
  return (
    <S.FooterDiv>
      <div>
        <Instagram />
        <Whatsapp />
        <GoogleDrive />
        <Youtube />
      </div>
      <p>travessias.psicanalise@gmail.com </p>
      <p>(31) 99853-3003</p>
    </S.FooterDiv>
  );
}

export default Footer;
