import React from 'react';
import { NotFoundDiv } from './styles';

function NotFound() {
  return (
    <NotFoundDiv>
      <p>Not Found</p>
    </NotFoundDiv>
  );
}

export default NotFound;
