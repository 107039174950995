import React from 'react';
import googleDrive from '../../images/icons/google-drive.svg';

function GoogleDrive({
  param = 'https://drive.google.com/drive/folders/1V6HzMm1dAmu2HPSSVUrEMyRXP4oNAKsP',
}: any) {
  return (
    <a href={param} target="_blank" className="ext" rel="noreferrer">
      <img src={googleDrive} alt="googleDrive logo" />
    </a>
  );
}

export default GoogleDrive;
