import React from 'react';
import * as S from './styles';
import Instagram from '../../components/Links/Instagram';
import GoogleDrive from '../../components/Links/GoogleDrive';
import Youtube from '../../components/Links/Youtube';
import Whatsapp from '../../components/Links/Whatsapp';

function Contact() {
  return (
    <S.Main>
      <S.Container>
        <S.Div>
          <h1>Contato</h1>
          <p>Sinta-se a vontade para nos enviar uma mensagem!</p>

          <p>
            Pedimos que nos dê o máximo de informações possíveis para que
            possamos te dar o melhor retorno possível.
          </p>
          <S.Links>
            <Instagram />
            <Whatsapp />
            <GoogleDrive />
            <Youtube />
          </S.Links>
        </S.Div>
        <S.Form
          action="https://formsubmit.co/travessias.psicanalise@gmail.com
          "
          method="POST"
        >
          <p>Enviar uma Mensagem</p>
          <input type="text" name="name" placeholder="Nome" required />
          <input type="email" name="email" placeholder="E-mail" />
          <input type="text" name="text" placeholder="Assunto" />
          <textarea rows={5} placeholder="Conteúdo" />
          <button type="submit">Enviar</button>
        </S.Form>
      </S.Container>
    </S.Main>
  );
}

export default Contact;
